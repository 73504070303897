
.App {
  width: 100vw;
}


/* Commom Area */

.d-none {
  display: none;
}

.main-title {
  text-align: center;
  font-size: .9rem;
  font-family: 'Press Start 2P', cursive;
  text-shadow: 2px 2px 2px #000;
  margin: 0 auto 2rem auto;
  background-color: #e82323;
  border-radius: 5px;
  padding: .5rem 1rem;
  border: 3px ridge #fff;
  width: max-content;
  color: #fff;
  max-width: 100%;
  font-weight: lighter;
}

.-minWidthMaxContent {
  min-width: max-content;
}

main {
  width: 100vw;
  min-height: 70vh;
}


section {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 3rem;
}

section div {
  height: 100%;
  width: 100%;
}


@media (max-width: 810px)
{
  .main-title {
      font-size: .7rem;
      margin-top: 3rem;
  }
  section {
      padding-right: 2px;
      padding-left: 2px;
      padding-top: .5rem;
  }
}

@media (max-width: 425px)
{
  .main-title {
      margin-bottom: 1rem;
  }

  .main-title {
      font-size: .7rem;
  }
}